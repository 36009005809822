const newElement = (tag: string): Element => document.createElement(tag);

class Gui {
    container = newElement("DIV");
    tree: { [key: string]: Element } = {
        container: newElement("DETAILS"),
        topic: newElement("SUMMARY"),
        iterations: newElement("INPUT"),
        depthMin: newElement("INPUT"),
        depthMax: newElement("INPUT"),
        branchLength: newElement("INPUT"),
        treeWidth: newElement("INPUT"),
        rotation: newElement("INPUT"),
        seed: newElement("INPUT")
    };

    filters: { [key: string]: {[key: string]: Element} | Element } = {
        container: newElement("DETAILS"),
        blur: {
            radius: newElement("INPUT")
        }
    };

    constructor() {
        const filtersContainer = this.filters.container as HTMLElement;
        filtersContainer.classList.add("gui-container");
        Object.keys(this.filters).forEach(id => {
            if(id !== "container") {
                const filter = this.filters[id] as {[key:string]: HTMLInputElement};
                Object.keys(this.filters[id]).forEach(key => {
                    const element = filter[key];
                });
            }
        });
        this.tree.container.classList.add("gui-container");
        this.tree.topic.innerHTML = "Muokkaa puuta";
        this.tree.topic.onclick =  e => {
          e.target.classList.toggle("summary-open");
        };
        this.tree.container.appendChild(this.tree.topic);
        this.container.appendChild(this.tree.container);
        const iterations = this.tree.iterations as HTMLInputElement;
        iterations.type = "range";
        iterations.setAttribute("min", "1");
        iterations.setAttribute("max", "10");
        iterations.setAttribute("value", "7");
        this.tree.container.appendChild(iterations);
        const depthMin = this.tree.depthMin as HTMLInputElement;
        depthMin.type = "range";
        depthMin.setAttribute("min", "1");
        depthMin.setAttribute("max", "5");
        this.tree.container.appendChild(depthMin);
        const depthMax = this.tree.depthMax as HTMLInputElement;
        depthMax.type = "range";
        depthMax.setAttribute("min", "1");
        depthMax.setAttribute("max", "10");
        this.tree.container.appendChild(depthMax);
        const branchLength = this.tree.branchLength as HTMLInputElement;
        branchLength.type = "range";
        branchLength.setAttribute("step", "1");
        branchLength.setAttribute("min", "0");
        branchLength.setAttribute("max", "100");
        this.tree.container.appendChild(branchLength);
        const rotation = this.tree.rotation as HTMLInputElement;
        rotation.type = "range";
        rotation.setAttribute("step", "0.01");
        rotation.setAttribute("min", "0");
        rotation.setAttribute("max", (1).toString());
        rotation.setAttribute("value", "0.5");
        this.tree.container.appendChild(rotation);
        const width = this.tree.treeWidth as HTMLInputElement;
        width.type = "range";
        width.setAttribute("step", "0.01");
        width.setAttribute("min", "1");
        width.setAttribute("max", "100");
        width.setAttribute("value", "20");
        this.tree.container.appendChild(width);
        const seed = this.tree.seed as HTMLInputElement;
        seed.type = "range";
        seed.setAttribute("step", "0.01");
        seed.setAttribute("min", "1");
        seed.setAttribute("max", "5");
        this.tree.container.appendChild(seed);
    }
}

export { Gui };
