import P5 from "p5";

export class Branch {
  tip: P5.Vector;
  root: P5.Vector;
  children: Branch[];
  width: number;
  color: number[] = [0,0,0];

  constructor() {
    this.tip = new P5.Vector();
    this.root = new P5.Vector();
    this.children = [];
    this.width = 1;
  }
}
