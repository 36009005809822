import P5 from "p5";
import { Branch } from "./branch";

export interface TreeSettings {
    seed: number;
    depthMin: number;
    depthMax: number;
    iterations: number;
    lengthScale: number;
    randomLengthScale: number;
    rotationScale: number;
    randomRotationScale: number;
    width: number;
    length: number;
    origin: P5.Vector;
    canvas: P5.Graphics;
}

export class Tree {
    p5: P5;
    branches: Branch[];
    settings: TreeSettings;
    needsUpdate = true;
    constructor(p5: P5, settings: TreeSettings) {
        this.p5 = p5;
        this.settings = settings;
        this.branches = [];
        this.init();
    }

    createBranches(
        t: Branch[],
        b: Branch,
        i: number,
        d: number,
        r: number
    ): void {
        if (d > 0) {
            d -= 1;
            const dir = P5.Vector.sub(b.tip, b.root).normalize();
            const dst = b.root.dist(b.tip) / i;
            const dI = this.settings.depthMax - d;
            for (let j = 0; j < i; j++) {
                const rp = P5.Vector.add(
                    b.root,
                    dir.copy().mult(dst * (j + 1))
                );
                const np = P5.Vector.add(
                    rp,
                    dir
                        .copy()
                        .rotate(
                            this.p5.map(
                                this.p5.noise((j + d) * r + this.settings.seed),
                                j % 2,
                                (j + 1) % 2,
                                -Math.PI * this.settings.rotationScale - (1/i),
                                Math.PI * this.settings.rotationScale + (1/i)
                            )
                        )
                        .mult(
                            this.p5.map(
                                this.p5.noise(
                                    (j) * this.settings.randomLengthScale -
                                        this.settings.seed
                                ),
                                0,
                                1,
                                this.settings.lengthScale * Math.pow(d, 0.9) * r,
                                this.settings.lengthScale * Math.pow(d, 0.9)
                            )
                        )
                );
                const nb = new Branch();
                nb.color[0] = dI * 30;
                nb.color[1] = dI * 5;
                nb.color[2] = dI * 15;
                nb.width = this.p5.map(
                    dI,
                    0,
                    this.settings.depthMax,
                    this.settings.width * 0.8,
                    0
                );
                nb.root.set(rp);
                nb.tip.set(np);
                b.children.push(nb);
                t.push(nb);
                this.createBranches(
                    t,
                    nb,
                    Math.round(this.settings.iterations * r),
                    d - Math.round(this.p5.noise((r + 1) * (j + dI))),
                    this.p5.noise(np.x + rp.x, np.y + rp.y)
                );
            }
        }
    }

    drawBranches(branches: Branch[]): void {
        this.settings.canvas.push();
        this.settings.canvas.translate(this.settings.origin.x, this.settings.origin.y);
        branches.forEach((b, i) => {
            this.settings.canvas.stroke(
                this.p5.color(b.color[0], b.color[1], b.color[2])
            );
            this.settings.canvas.strokeWeight(b.width + 0.5);
            this.settings.canvas.line(b.root.x, b.root.y, b.tip.x, b.tip.y);
        });
        this.settings.canvas.pop();
    }

    getRandomBranch(root: Branch, seed: number): P5.Vector[] {
        const branches: P5.Vector[] = [];
        let branch = root;
        let iterate = true;
        let i = 0;
        while (branch.children.length > 0 && iterate) {
            const rnd = Math.round(
                this.p5.noise(seed + i) * (branch.children.length - 1)
            );
            branch = branch.children[rnd];
            if(this.p5.noise(seed, seed) > this.p5.noise(seed * rnd, seed * rnd)) {
              if (branch.children.length < 1) branches.push(branch.tip.copy());
              else branches.push(branch.root.copy());
            } else {
              if(branches.length > 2)
                iterate = false;
              branches.push(branch.root.copy());
            }
            i += 1;
        }

        return branches;
    }

    updateCanvas(): void {
        this.settings.canvas.clear();
        this.settings.canvas.background("rgba(255,255,255,0)");
        this.drawBranches(this.branches);
        this.needsUpdate = true;
    }

    init(): void {
        this.settings.origin.x = this.p5.width / 2;
        this.settings.origin.y = this.p5.height;
        const stem = new Branch();
        stem.width = this.settings.width;
        stem.root = new P5.Vector();
        stem.tip.x = 0;
        stem.tip.y = -200;
        this.branches.push(stem);
        this.createBranches(
            this.branches,
            stem,
            this.settings.iterations,
            this.settings.depthMin,
            1
        );
        this.branches[0].color = this.branches[0].children[0].color;
        this.updateCanvas();
    }

    draw(): void {
        this.p5.image(this.settings.canvas, 0, 0);
        this.needsUpdate = false;
    }

    resize(): void {
        this.settings.origin.x = this.p5.width / 2;
        this.settings.origin.y = this.p5.height;
        this.updateCanvas();
    }
}
