interface TouchObj {
    identifier: number;
    clientX: number;
    clientY: number;
    dir?: number[];
    prevTouch?: TouchObj;
}

class TouchHandler {
    pool: TouchObj[] = [];
    ctx: HTMLElement;
    constructor(ctx: HTMLElement) {
        this.ctx = ctx;
        this.ctx.addEventListener(
            "touchstart",
            (e) => this.handleStart(e),
            false
        );
        this.ctx.addEventListener(
            "mousedown",
            (e) => this.handleStart(e),
            false
        );
        this.ctx.addEventListener("touchend", (e) => this.handleEnd(e), false);
        this.ctx.addEventListener("mouseup", (e) => this.handleEnd(e), false);
        this.ctx.addEventListener(
            "touchcancel",
            (e) => this.handleCancel(e),
            false
        );
        this.ctx.addEventListener(
            "touchmove",
            (e) => this.handleMove(e),
            false
        );
    }

    isMouseEvent(e: MouseEvent | TouchEvent): e is MouseEvent {
        return e.type === "mouseup"; // || e.type === 'click'...
    }

    cloneTouch(e: Touch): TouchObj {
        return {
            identifier: e.identifier,
            clientX: e.clientX,
            clientY: e.clientY
        };
    }

    cloneMouse(e: MouseEvent): TouchObj {
        return {
            identifier: 0,
            clientX: e.clientX,
            clientY: e.clientY
        };
    }

    addTouches(e: TouchEvent): void {
        const touches = e.changedTouches;
        Object.values(touches).forEach((t) => {
            const touch = this.cloneTouch(t);
            console.log(touch);
            this.pool.push(touch);
        });
    }

    removeTouches(e: TouchEvent): void {
        const touches = e.changedTouches;
        Object.values(touches).forEach((t, i) => {
            this.pool.forEach((l) => {
                if (t.identifier === l.identifier) {
                    this.pool.splice(i, 1);
                }
            });
        });
    }

    updateTouches(e: TouchEvent): void {
        const touches = e.changedTouches;
        Object.values(touches).forEach((t) => {
            this.pool.forEach((l, i) => {
                if (t.identifier === l.identifier) {
                    const touch = {
                        ...this.cloneTouch(t),
                        dir: [t.clientX - l.clientX, t.clientY - l.clientY],
                        prevTouch: l
                    };
                    this.pool[i] = touch;
                }
            });
        });
    }

    handleStart(e: TouchEvent | MouseEvent): void {
        e.preventDefault();
        if (e instanceof TouchEvent) this.addTouches(e);
        else this.pool.push({ ...this.cloneMouse(e) });
    }

    handleEnd(e: TouchEvent | MouseEvent): void {
        e.preventDefault();
        if (e instanceof TouchEvent) this.removeTouches(e);
        else this.pool.pop();
        if (this.pool.length < 1) {
            this.pool = [];
        }
    }

    handleCancel(e: TouchEvent | MouseEvent): void {
        e.preventDefault();
        if (e instanceof TouchEvent) this.removeTouches(e);
    }

    handleMove(e: TouchEvent | MouseEvent): void {
        e.preventDefault();
        console.log("MOVING");
        if (e instanceof TouchEvent) this.updateTouches(e);
    }
}

export { TouchHandler };
