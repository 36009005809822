import P5 from "p5";
import {Song} from "./song";

export class Songs {
    p5: P5;
    media: Song[] = [];
    constructor(
        p5: P5,
        media: Song[] = []
    ) {
        this.p5 = p5;
        this.media = media;
    }

    async update(): Promise<void> {
        this.media.forEach((item) => {
            const dist = item.targets[item.target].dist(item.position);
            if (dist < 2) {
                if (item.target < item.targets.length - 1) {
                    item.target++;
                } else if(!item.button.elt.classList.contains("button-animation" && item.targets.length < 1))
                    item.button.elt.classList.add("button-animation");
            } else if (dist > 1) {
                const d = item.velocity
                    .add(
                        P5.Vector.sub(item.targets[item.target], item.position)
                            .normalize()
                            .mult(item.speed)
                    )
                    .mult(item.friction / this.p5.deltaTime);
                item.position.add(d);
            }
        });
        return await Promise.resolve();
    }

    draw(xOff = 0, yOff = 0): void {
        this.media.forEach((item) => {
            this.p5.fill(item.color);
            const x = (this.p5.width / 2) + item.position.x + xOff;
            const y = this.p5.height + item.position.y + yOff;
            const position = "translate(" +
                      x +  "px," +
                      y + "px)";
            item.button
            //? item.button.style("transform", position)
                ? item.button.position(x,y)
                : null;
        });
    }
}
