import P5 from "p5";
import { Tree } from "./tree";
import { Song } from "./song";
import { Songs } from "./songs";
import { TouchHandler } from "./touch";
import { Gui } from "./gui";

Number.prototype.mod = function(n) {
    return ((this%n)+n)%n;
};

const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;

interface SongData {
    name: string;
    link: string;
    text: string[];
}

const path = "../audio/";

const songDatas: SongData[] = [
    {
        name: "Työstä",
        link: path + "tyosta.mp3",
        text: []
    },
    {
        name: "Rahan synty",
        link: path + "rahan_synty.mp3",
        text: []
    },
    {
        name: "Kaikukapitalisti",
        link: path + "kaikukapitalisti.mp3",
        text: []
    },
    {
        name: "Tavaroista",
        link: path + "tavaroista.mp3",
        text: []
    },
    {
        name: "Velasta",
        link: path + "velasta.mp3",
        text: ["Velka", "Sitä on kaikilla","Velka", "Sitä on kaikilla","Velka", "Sitä on kaikilla","Velka", "Sitä on kaikilla","Velka", "Sitä on kaikilla"]
    },
    {
        name: "Erikoistumisesta",
        link: path + "erikoistumisesta.mp3",
        text: []
    },
    {
        name: "Silmu 1",
        link: path + "silmu1.mp3",
        text: []
    },
    {
        name: "Silmu 2",
        link: path + "silmu2.mp3",
        text: []
    },
    {
        name: "Silmu 3",
        link: path + "silmu3.mp3",
        text: []
    },
    {
        name: "Silmu 4",
        link: path + "silmu4.mp3",
        text: []
    },
    {
        name: "Silmu 5",
        link: path + "silmu5.mp3",
        text: []
    },
    {
        name: "Silmu 6",
        link: path + "silmu6.mp3",
        text: []
    },
    {
        name: "Silmu 7",
        link: path + "silmu7.mp3",
        text: []
    },
    {
        name: "Silmu 8",
        link: path + "silmu8.mp3",
        text: []
    },
    {
        name: "Silmu 9",
        link: path + "silmu9.mp3",
        text: []
    },
    {
        name: "Silmu 10",
        link: path + "silmu10.mp3",
        text: []
    },
    {
        name: "Silmu 11",
        link: path + "silmu11.mp3",
        text: []
    },
    {
        name: "Silmu 12",
        link: path + "silmu12.mp3",
        text: []
    },
    {
        name: "Silmu 13",
        link: path + "silmu13.mp3",
        text: []
    },
    {
        name: "Silmu 14",
        link: path + "silmu14.mp3",
        text: []
    },
    {
        name: "Silmu 15",
        link: path + "silmu15.mp3",
        text: []
    },
    {
        name: "Silmu 16",
        link: path + "silmu16.mp3",
        text: []
    },
    {
        name: "Silmu 17",
        link: path + "silmu17.mp3",
        text: []
    },
    {
        name: "Silmu 18",
        link: path + "silmu18.mp3",
        text: []
    },
    {
        name: "Silmu 19",
        link: path + "silmu19.mp3",
        text: []
    },
    {
        name: "Silmu 20",
        link: path + "silmu20.mp3",
        text: []
    },
    {
        name: "Silmu 21",
        link: path + "silmu21.mp3",
        text: []
    },
    {
        name: "Silmu 22",
        link: path + "silmu22.mp3",
        text: []
    },
    {
        name: "Silmu 23",
        link: path + "silmu23.mp3",
        text: []
    },
    {
        name: "Silmu 24",
        link: path + "silmu24.mp3",
        text: []
    },
    {
        name: "Silmu 25",
        link: path + "silmu25.mp3",
        text: []
    }
];

const sketch = (p5: P5) => {
    const canvas = p5.createCanvas(window.innerWidth, window.innerHeight);
    let treeCanvas = p5.createGraphics(window.innerWidth, window.innerHeight);
    const gui = new Gui();
    let tree: Tree;
    let songs: Songs;
    let activeSong: Song;
    const settings = {
        origin: new P5.Vector(),
        seed: 1,
        depthMin: 5,
        depthMax: 5,
        iterations: 5,
        columns: 1,
        rotationScale: 0.5,
        randomRotationScale: 7.5,
        lengthScale: 20,
        randomLengthScale: 0.5,
        width: 10,
        length: 10,
        canvas: treeCanvas
    };
    const blackList = ["container", "topic"];
    const touch = new TouchHandler(document.querySelector("#app")!);

    p5.setup = async (): Promise<void> => {
        const resize = () => {
                const headerHeight = document.querySelector("header").offsetHeight;
                p5.resizeCanvas(window.innerWidth, window.innerHeight - headerHeight);
                treeCanvas.remove();
                treeCanvas = p5.createGraphics(window.innerWidth, window.innerHeight);
                settings.canvas = treeCanvas;
                tree.resize();
                tree.updateCanvas();
        };
        canvas.parent("app");
        const iterations = gui.tree.iterations as HTMLInputElement;
        const depthMin = gui.tree.depthMin as HTMLInputElement;
        const depthMax = gui.tree.depthMax as HTMLInputElement;
        const branchLength = gui.tree.branchLength as HTMLInputElement;
        const rotation = gui.tree.rotation as HTMLInputElement;
        const width = gui.tree.treeWidth as HTMLInputElement;
        const seed = gui.tree.seed as HTMLInputElement;
        const newTree = async (): Promise<Tree> => {
            settings.iterations = parseFloat(iterations.value);
            settings.depthMin = parseFloat(depthMin.value);
            settings.depthMax = parseFloat(depthMax.value);
            settings.lengthScale = parseFloat(branchLength.value);
            settings.rotationScale = parseFloat(rotation.value);
            settings.width = parseFloat(width.value);
            settings.seed = parseFloat(seed.value);

            const headerHeight = document.querySelector("header").offsetHeight;
            p5.resizeCanvas(window.innerWidth, window.innerHeight - headerHeight);
            const tree =  new Tree(p5, settings);
            tree.resize();
            return Promise.resolve(tree);
        };

        p5.noiseSeed(0);
        tree = await newTree();
        const newSongs = (): Song[] => {
            const songList: Song[] = [];
            songDatas.forEach((data: SongData, i: number) => {
                const branch = tree.branches[0];
                const song = new Audio(data.link);
                song.preload = "true";
                song.load();
                const button = p5.createButton("");
                button.parent("buttons");

                const songData = {
                    name: data.name,
                    audio: song,
                    targets: tree.getRandomBranch(branch.children[i%branch.children.length], i),
                    target: 0,
                    position: branch.root.copy(),
                    velocity: branch.tip
                        .copy()
                        .sub(tree.branches[0].root)
                        .normalize(),
                    friction: 12,
                    speed: p5.noise(i * 10) + 0.005,
                    size: (p5.noise(i * 20) + 1.75) * 10,
                    text: data.name,
                    color: p5.color("hsl(" + ((i * 4) % 512) + ", 100%, 50%)"),
                    button: button,
                    lyrics: data.text.length > 0 ? data.text : null
                };

                songData.audio.addEventListener("ended", e => {
                  activeSong.button.elt.classList.remove("playing");
                  const id = songs.media.indexOf(songData);
                  activeSong = songs.media[(id + 1) % songs.media.length];
                  activeSong.audio.crossOrigin = "anonymous";
                  const playing = activeSong.audio.play();
                  activeSong.button.elt.classList.add("playing");
                  if (playing !== undefined) {
                      playing
                          .then(function () {
                              console.log("Playing");
                              // Automatic playback started!
                          })
                          .catch(function (error) {
                              // Automatic playback failed.
                              // Show a UI element to let the user manually start playback.
                          });
                  }
                  document.querySelector(".timeline").style.width = 0 + "%";
                  document.querySelector(".name").innerHTML = activeSong.name;
                });

                songData.audio.addEventListener("timeupdate", e => {
                    /*
                    const lyrics = document.querySelector(".lyrics");
                    if(songData.lyrics) {
                        const offset = songData.audio.duration / songData.lyrics.length;
                        const currentLyric = songData.lyrics[Math.floor(songData.audio.currentTime / offset)];
                        if(lyrics.innerHTML !== currentLyric && currentLyric) {
                            lyrics.innerHTML = currentLyric;
                        } 
                    } else if(!songData.lyrics && lyrics.innerHTML !== "") {
                        lyrics.innerHTML = "";
                    }
                    */
                    const pos = map(songData.audio.currentTime, 0, songData.audio.duration, 0, 100);
                    document.querySelector(".duration").innerHTML = Math.floor(songData.audio.currentTime / 60) + ":" + songData.audio.currentTime.toFixed(0) % 60;
                    document.querySelector(".timeline").style.width = pos + "%";
                });

                button.elt.classList.add(data.name.replace(/\s+/g, '_'));
                button.mouseClicked(() => {
                    songs.media.forEach((song) => {
                        song.audio.pause();
                        song.audio.currentTime = 0;
                        song.button.elt.classList.remove("playing");
                    });
                    button.elt.classList.add("playing");
                    song.crossOrigin = "anonymous";
                    song.load();
                    const playing = song.play();
                    if (playing !== undefined) {
                        playing
                            .then(function () {
                                console.log("Playing");
                                // Automatic playback started!
                            })
                            .catch(function (error) {
                                // Automatic playback failed.
                                // Show a UI element to let the user manually start playback.
                            });
                    }
                    document.querySelector(".play").classList.add("pause");
                    document.querySelector(".name").innerHTML = data.name;
                    activeSong = songData;
                });

                songList.push(songData);
            });
            return songList;
        };
        songs = new Songs(p5, newSongs());

        const updateSongs = (): void => {
            const branch = tree.branches[0];
            songs.media.forEach((song: Song, i: number) => {
                    song.button.elt.classList.remove("button-animation");
                    song.targets = tree.getRandomBranch(branch.children[i%branch.children.length], i);
                    song.target = 0;
                    song.position = branch.root.copy();
                    song.velocity = branch.tip
                        .copy()
                        .sub(tree.branches[0].root)
                        .normalize();
            });
        };

        document.querySelector(".gui")!.appendChild(gui.container);
          Object.keys(gui.tree).forEach((key: string) => {
            const input = gui.tree[key] as HTMLInputElement;
            if (!blackList.includes(key) && input.type) {
                input.classList.add("slider");
                input.setAttribute("data-value", key + " : " + input.value);
                input.onchange = async (): Promise<void> => {
                    tree = await newTree();
                    tree.updateCanvas();
                    updateSongs();
                    input.setAttribute("data-value", key + " : " + input.value);
                    return Promise.resolve();
                };
            }
        });

        document.querySelector(".play").addEventListener("click", e => {
        const button = document.querySelector("." + activeSong.name.replace(/\s+/g, '_'));
          if(e.target.classList.contains("pause")) {
            button.classList.remove("playing");
            e.target.classList.remove("pause");
            activeSong.audio.pause();
          } else {
            button.classList.add("playing");
            e.target.classList.add("pause");    
			console.log(activeSong);
            activeSong.audio.play();
          }
        });

        document.querySelector(".prev").addEventListener("click", e => {
          let button = document.querySelector("." + activeSong.name.replace(/\s+/g, '_'));
          button.classList.remove("playing");
          activeSong.audio.pause();
          activeSong.audio.currentTime = 0;
          const id = songs.media.indexOf(activeSong);
          activeSong = songs.media[(id - 1).mod(songs.media.length)];
          activeSong.crossOrigin = "anonymous";
          button = document.querySelector("." + activeSong.name.replace(/\s+/g, '_'));
          button.classList.add("playing");
          document.querySelector(".play").classList.add("pause");    
          const playing = activeSong.audio.play();
          if (playing !== undefined) {
              playing
                  .then(function () {
                      console.log("Playing");
                      // Automatic playback started!
                  })
                  .catch(function (error) {
                      // Automatic playback failed.
                      // Show a UI element to let the user manually start playback.
                  });
          }
          document.querySelector(".name").innerHTML = activeSong.name;
        });

        document.querySelector(".next").addEventListener("click", e => {
          let button = document.querySelector("." + activeSong.name.replace(/\s+/g, '_'));
          button.classList.remove("playing");
          activeSong.audio.pause();
          activeSong.audio.currentTime = 0;
          const id = songs.media.indexOf(activeSong);
          activeSong = songs.media[(id + 1) % songs.media.length];
          button = document.querySelector("." + activeSong.name.replace(/\s+/g, '_'));
          button.classList.add("playing");
          activeSong.crossOrigin = "anonymous";
          const playing = activeSong.audio.play();
          document.querySelector(".play").classList.add("pause");    
          if (playing !== undefined) {
              playing
                  .then(function () {
                      console.log("Playing");
                      // Automatic playback started!
                  })
                  .catch(function (error) {
                      // Automatic playback failed.
                      // Show a UI element to let the user manually start playback.
                  });
          }
          document.querySelector(".name").innerHTML = activeSong.name;
        });

        activeSong = songs.media[0];
        document.querySelector(".name").innerHTML = activeSong.name;

        window.onresize = resize;

        return Promise.resolve();
    };

    p5.draw = () => {
      if(tree) {
        if(tree.needsUpdate){
          p5.background("rgba(0%,0%,0%,0)");
          tree.draw();
        }
        if(songs) {
          songs.update();
          songs.draw(-18, -18);
        }
      }
    };
};

new P5(sketch);
